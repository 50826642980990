export enum ECaseStatus {
  NEW_REQUEST = 'new_request',
  OPEN = 'open',
  ASSIGNED = 'assigned',
  ACCEPTED = 'accepted',
  ARRIVED_AT_CUSTOMER = 'arrived_at_customer',
  ARRIVED_AT_WORKSHOP = 'arrived_at_workshop',
  ARRIVED_AT_POLICE_STATION = 'arrived_at_police_station',
  TOWING_TO_WORKSHOP = 'towing_to_workshop',
  CANCELLED = 'cancelled',
  RESOLVED = 'resolved',
  CLOSED = 'closed',
  ERROR = 'error',
  IN_PROGRESS = 'in_progress',
}

export enum ECaseSubStatus {
  PENDING_ACTION = 'pending_action',
  PENDING_REVIEW = 'pending_review'
}
