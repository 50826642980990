import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { stringify } from 'qs';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigService } from '@ata/shared/data-access/configs';
import { BaseApi } from '@ata/utils';
import { ICasesQuery } from '../dtos/cases-query.dto';
import { Case } from '../models/case.model';

/**
 * @deprecated
 */
@Injectable({
  providedIn: 'root',
})
export class CasesService {
  constructor(private api: BaseApi, private configService: ConfigService) {}

  /**
   * Get cases count
   *
   * @param query ICasesQuery
   * @returns number
   */
  public getCasesCount = (query?: ICasesQuery): Observable<number> => {
    let params = new HttpParams();

    if (query && Object.keys(query).length > 0) {
      Object.keys(query).forEach((key) => {
        if (key.charAt(0) !== '_') {
          const value: any = query[key];

          if (value instanceof Array) {
            value.forEach((v) => {
              params = params.append(key, v);
            });
          } else {
            params = params.append(key, value);
          }
        }
      });
    }

    return this.api.get(`${this.configService.config.cmsEndPoint}/cases/count`, params).pipe(map((response) => response.body));
  };

  /**
   * Get cases
   *
   * @param query ICasesQuery
   * @returns Observable<Array<Case>>
   */
  public getCases = (query?: ICasesQuery): Observable<Array<Case>> => {
    let params = new HttpParams();

    if (query && Object.keys(query).length > 0) {
      Object.keys(query).forEach((key) => {
        const value: any = query[key];

        if (value instanceof Array) {
          value.forEach((v) => {
            params = params.append(key, v);
          });
        } else {
          params = params.append(key, value);
        }
      });
    }

    return this.api.get(`${this.configService.config.systemEndpoint}/cases`, params).pipe(map((response) => response.body.map((c) => new Case(c))));
  };

  public getCasesV2 = (
    query: any,
    options: any
  ): Observable<{
    data: Case[];
    code: number;
    meta: any;
    success: boolean;
  }> => {
    const urlParams = stringify({ ...query, options }, { encode: false });

    return this.api.get(`${this.configService.config.systemEndpointV2}/cases?${urlParams}`).pipe(
      map((response) => {
        return {
          data: response.body.data.map((c) => new Case(c)),
          code: response.status,
          meta: response.body.meta,
          success: response.ok,
        };
      })
    );
  };

  /**
   * Register case
   *
   * @param payload
   * @returns Observable<Case>
   * @description for ATAU usage
   * @todo define payload interface/dto
   */
  public registerCase = (payload: Case): Observable<Case> => {
    // Validate destination type if present
    if (payload.destination && payload.destination.type) {
      // Ensure destination type is either 'panel' or 'preferred'
      if (payload.destination.type !== 'panel' && payload.destination.type !== 'preferred') {
        return throwError(() => new Error('Invalid destination type. Must be "panel" or "preferred".'));
      }
    }

    // For each job, validate destination type
    if (payload.jobs && Array.isArray(payload.jobs)) {
      for (const job of payload.jobs) {
        if (job.destination && job.destination.type) {
          if (job.destination.type !== 'panel' && job.destination.type !== 'preferred') {
            return throwError(() => new Error('Invalid job destination type. Must be "panel" or "preferred".'));
          }
        }
      }
    }

    return this.api.post(`${this.configService.config.systemEndpointV2}/cases`, payload).pipe(map((response) => new Case(response.body?.data)));
  };

  /**
   * Cancel case
   *
   * @param payload
   * @param caseId
   * @returns Observable<Case>
   * @description for ATAU usage
   * @todo define payload interface/dto
   */
  public cancelCase = (payload: any, caseId: string): Observable<Case> => {
    return this.api.put(`${this.configService.config.systemEndpoint}/cases/cancellation/${caseId}`, payload).pipe(map((response) => new Case(response.body)));
  };

  /**
   * Get case detail by case id
   *
   * @param caseId
   * @returns Observable<Case>
   * @description get case detail by case id using CMS endpoint
   */
  public getCaseById = (caseId: string): Observable<Case> => {
    return this.api.get(`${this.configService.config.cmsEndPoint}/cases/${caseId}`).pipe(map((response) => new Case(response.body)));
  };

  /**
   * Get case detail by ticket and vehicle no
   *
   * @param ticketId
   * @param vehicleNo
   * @returns Observable<any>
   * @description get case detail by ticket and vehicle no
   */
  public verifyCaseTicket(ticketId: string, vehicleNo: string) {
    return this.api.get(`${this.configService.config.systemEndpoint}/cases/${ticketId}/${vehicleNo}`).pipe(map((response) => response.body));
  }

  /**
   * Update case
   *
   * @param payload
   * @param caseId
   * @returns Observable<Case>
   * @description update case detail
   * @todo define payload interface/dto
   */
  public updateCase(caseId: string, payload: any): Observable<Case> {
    return this.api.post(`${this.configService.config.systemEndpoint}/cases/update/${caseId}`, payload).pipe(map((response) => new Case(response.body)));
  }

  public backendUpdateCase(caseId: string, payload: any): Observable<Case> {
    return this.api.put(`${this.configService.config.systemEndpoint}/cases/${caseId}`, payload).pipe(map((response) => new Case(response.body)));
  }

  public dataToExcel(json: Array<any>) {
    const test: Array<any> = [];
    for (const data of json) {
      const caseProperties = Object.keys(data);
      const newData = {};

      // declare sequence of excel
      newData['Created date'] = '';
      newData['Channel'] = '';
      newData['TicketID'] = '';
      newData['Assignment ID'] = '';
      newData['Customer Name'] = '';
      newData['Customer Phone Number 1'] = '';
      newData['Customer Phone Number 2'] = '';
      newData['Customer Phone Number 3'] = '';
      newData['Entity'] = '';
      newData['Policy Benefit'] = '';
      newData['Vehicle Number'] = '';
      newData['Vehicle Make'] = '';
      newData['Vehicle Model'] = '';
      newData['Service Required'] = '';
      newData['Case Status'] = '';
      newData['Mileage'] = '';
      newData['Add on Services'] = '';
      newData['Provider Name'] = '';
      newData['Provider Type'] = '';
      newData['Customer Location'] = '';
      newData['Police Station Address'] = '';
      newData['Workshop Name'] = '';
      newData['Workshop Address'] = '';
      newData['State'] = '';
      newData['Region'] = '';
      newData['Charge to Etiqa'] = '';
      newData['Collect From Customer'] = '';
      newData['Total'] = '';
      newData['Time Case Opened'] = '';
      newData['Time Case Assigned'] = '';
      newData['Time Case Accepted'] = '';
      newData['Time Case Arrived at Customer'] = '';
      newData['Time Case Arrived at Workshop'] = '';
      newData['Case Arrived at Police Station'] = '';
      newData['Time Case Towing to Workshop'] = '';
      newData['Time Case Cancelled'] = '';
      newData['Time Case Resolved'] = '';
      newData['Time Case Closed'] = '';

      for (const caseProp of caseProperties) {
        if (
          caseProp === 'id' ||
          caseProp === '_v' ||
          caseProp === '_id' ||
          caseProp === 'smsNotify' ||
          caseProp === 'attachment' ||
          caseProp === 'event' ||
          caseProp === 'notifications' ||
          caseProp === '__v' ||
          caseProp === 'campaign' ||
          caseProp === 'users' ||
          caseProp === 'carCondition' ||
          caseProp === 'debugErrorCount'
        ) {
          continue;
        } else if (caseProp === 'channel') {
          newData['Channel'] = data[caseProp];
        } else if (caseProp === 'ticketId') {
          newData['TicketID'] = data[caseProp];
        } else if (caseProp === 'assignmentId') {
          newData['Assignment ID'] = data[caseProp];
        } else if (caseProp === 'name' || caseProp === 'phoneNo' || caseProp === 'altPhoneNo') {
          newData['Customer Name'] = data?.name;
          newData['Customer Phone Number 1'] = data?.phoneNo;
          newData['Customer Phone Number 2'] = data?.altPhoneNo?.[0];
          newData['Customer Phone Number 3'] = data?.altPhoneNo?.[1];
        } else if (caseProp === 'entity') {
          newData['Entity'] = data[caseProp];
        } else if (caseProp === 'policyCoverage') {
          newData['Policy Benefit'] = data[caseProp];
        } else if (caseProp === 'vehicleNo') {
          newData['Vehicle Number'] = data[caseProp];
        } else if (caseProp === 'vehicleBrand') {
          newData['Vehicle Make'] = data[caseProp];
        } else if (caseProp === 'vehicleModel') {
          newData['Vehicle Model'] = data[caseProp];
        } else if (caseProp === 'services') {
          const services: Array<string> = [];
          if (data[caseProp].length > 0) {
            const servicesProperties = Object.keys(data.services);
            for (const serviceProp of servicesProperties) {
              services.push(data[caseProp][serviceProp].service);
            }
          }
          newData['Service Required'] = services.toString();
        } else if (caseProp === 'addOnService' && data.addOnService?.length > 0 && (data.addOnService?.length === 1 && data.addonService?.[0]) !== '0') {
          const addOn: Array<string> = [];
          data.addOnService.forEach((dataId) => {
            const addonName = this.getAddonName(dataId);
            if (addonName !== '') {
              addOn.push(this.getAddonName(dataId));
            }
          });
          newData['Add on Services'] = addOn.toString();
        } else if (caseProp === 'status') {
          newData['Case Status'] = data[caseProp];
        } else if (caseProp === 'distance') {
          newData['Mileage'] = data[caseProp];
        } else if (caseProp === 'provider') {
          newData['Provider Type'] = data.provider?.name;
        } else if (caseProp === 'policeStation') {
          newData['Police Station Address'] = data.policeStation?.address;
        } else if (caseProp === 'towing' && data.towing?.usePreferredWorkshop !== undefined) {
          newData['Workshop Name'] = data?.workshop?.name;
          newData['State'] = data?.workshop?.state;
          if (data.towing?.usePreferredWorkshop) {
            newData['Workshop Address'] = data.workshop?.address1;
          } else {
            if (data?.workshop?.name) {
              newData['Workshop Address'] =
                data.workshop?.address1 +
                ', ' +
                data.workshop?.address2 +
                ', ' +
                data.workshop?.city +
                ', ' +
                data.workshop?.postcode +
                ', ' +
                data.workshop?.state;
            }
          }
        } else if (caseProp === 'tracking' && data.tracking !== null) {
          newData['Provider Name'] = data.tracking?.companySpName;
        } else if (caseProp === 'location') {
          newData['Customer Location'] = data?.location;
        } else if (caseProp === 'createdAt') {
          newData['Created date'] = new Date(data.createdAt).toLocaleString('en-GB');
        } else if (caseProp === 'billing' && data.billing !== null) {
          newData['Region'] = data.billing?.region;
          newData['Charge to Etiqa'] = data.billing?.amount - data.billing?.payByCustomer > 0 ? data.billing?.amount - data.billing?.payByCustomer : 0;
          newData['Collect From Customer'] = data.billing?.payByCustomer;
          newData['Total'] = data.billing?.amount;
        } else if (caseProp === 'activityLog' && data.activityLog?.length > 0) {
          data.activityLog.forEach((log) => {
            const logProperties = Object.keys(log);
            for (const logProp of logProperties) {
              if (logProp === 'status' && log?.status !== null) {
                switch (log?.status) {
                  case 'open':
                    newData['Time Case Opened'] = new Date(log.createdDate).toLocaleString('en-GB');
                    break;

                  case 'assigned':
                    newData['Time Case Assigned'] = new Date(log.createdDate).toLocaleString('en-GB');
                    break;

                  case 'accepted':
                    newData['Time Case Accepted'] = new Date(log.createdDate).toLocaleString('en-GB');
                    break;

                  case 'arrived_at_customer':
                    newData['Time Case Arrived at Customer'] = new Date(log.createdDate).toLocaleString('en-GB');
                    break;

                  case 'arrived_at_workshop':
                    newData['Time Case Arrived at Workshop'] = new Date(log.createdDate).toLocaleString('en-GB');
                    break;

                  case 'arrived_at_police_station':
                    newData['Case Arrived at Police Station'] = new Date(log.createdDate).toLocaleString('en-GB');
                    break;

                  case 'towing_to_workshop':
                    newData['Time Case Towing to Workshop'] = new Date(log.createdDate).toLocaleString('en-GB');
                    break;

                  case 'cancelled':
                    newData['Time Case Cancelled'] = new Date(log.createdDate).toLocaleString('en-GB');
                    break;

                  case 'resolved':
                    newData['Time Case Resolved'] = new Date(log.createdDate).toLocaleString('en-GB');
                    break;

                  case 'closed':
                    newData['Time Case Closed'] = new Date(log.createdDate).toLocaleString('en-GB');
                    break;
                }
              }
            }
          });
        }
      }
      test.push(newData);
    }
    return test;
  }

  getServiceAddon = (): Observable<any> => {
    return this.api.get(`${this.configService.config.cmsEndPoint}/addons`).pipe(map((response) => response.body));
  };

  private getAddonName(addonId) {
    let name = '';
    switch (addonId) {
      case '1':
        name = 'MPV / SUV / 4x4';
        break;

      case '2':
        name = 'Basement Towing';
        break;

      case '3':
        name = 'Baby Tyre (King Dolly)';
        break;

      case '4':
        name = 'Basement + Baby Tyre';
        break;

      case '5':
        name = 'Night shift (10pm-7am)';
        break;

      case '6':
        name = 'Highland Towing';
        break;

      case '7':
        name = 'Crane';
        break;

      case '8':
        name = 'GoJack';
        break;

      case '10':
        name = 'Luxury Car';
        break;
    }
    return name;
  }
}
