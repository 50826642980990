import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { generateJsHash } from '@ata/utils';

import { IPermissions } from '../interfaces/permissions.interface';

import { Permissions } from '../models/permissions.model';
import { PermissionsRole } from '../..';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  static allPermissions: Permissions;

  private _httpClient: HttpClient;

  static roleACL: PermissionsRole;

  constructor(handler: HttpBackend) {
    this._httpClient = new HttpClient(handler);
  }

  public loadAllRolesPermissions(path): Promise<IPermissions> {
    return new Promise<IPermissions>((resolve) => {
      this._httpClient
        .get(`${path}/access-control-list`)
        .toPromise()
        .then((response: IPermissions) => {
          PermissionsService.allPermissions = new Permissions(response);
          resolve(PermissionsService.allPermissions);
        });
    });
  }

  public loadFeatureFlags(path) {
    return new Promise<any>((resolve) => {
      this._httpClient
        .get(`${path}/feature-flags?isBe=true`)
        .toPromise()
        .then((response: any) => {
          const featureFlags = response.data;

          if (Object.keys(featureFlags).length > 0) {
            localStorage.setItem('feature-flags', JSON.stringify(featureFlags));
          } else {
            localStorage.removeItem('feature-flags');
          }

          resolve(response);
        });
    });
  }

  private getUserACL(): PermissionsRole {
    let userRole = '';
    // ATAP
    if(localStorage.getItem('dashboard-login')) {
      userRole = JSON.parse(localStorage.getItem('dashboard-login')).role;
    // ATA Partner 
    } else if (localStorage.getItem('auth.user')) {
      userRole = JSON.parse(localStorage.getItem('auth.user')).role.type;
    } 
    
    const hashed = generateJsHash(JSON.stringify(PermissionsService.allPermissions.allPermissions));

    if (PermissionsService.allPermissions?.hashedPermissions === hashed) {
      PermissionsService.roleACL = PermissionsService.allPermissions?.allPermissions.roles.find((r) => r.name.toLowerCase() === userRole);
      return PermissionsService.roleACL;
    }
    return null;
  }

  private getAclInit() {
    if (!PermissionsService.roleACL) {
      this.getUserACL();
    }
  }

  public checkCmsAccessControl(action: string, feature: string): boolean {
    this.getAclInit();

    const access = PermissionsService.roleACL?.permissions?.filter((acl) => acl.action === action && acl.controller === feature)[0]?.enabled;
    return access !== null ? access : false;
  }

  public checkIdentityAccessControl(action: string): boolean {
    this.getAclInit();

    let access: boolean;
    try {
      access = PermissionsService.roleACL.identity[action] ?? false; //in case null value in the database
      return access;
    } catch {
      return false;
    }
  }
}
