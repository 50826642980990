const getDistanceMatrix = (service: google.maps.DistanceMatrixService, data: google.maps.DistanceMatrixRequest) =>
  new Promise((resolve, reject) => {
    service.getDistanceMatrix(data, (response, status) => {
      if (status === 'OK') {
        resolve(response);
      } else {
        reject(response);
      }
    });
  });

export const getDistance = async (location: any, destination: any) => {
  // Validate input coordinates
  if (!location || !destination) {
    throw new Error('Missing location or destination coordinates');
  }

  // Convert to LatLng objects if they're not already
  let originLatLng: google.maps.LatLng;
  let destLatLng: google.maps.LatLng;

  // Handle different possible location formats
  if (location instanceof google.maps.LatLng) {
    originLatLng = location;
  } else {
    const lat = typeof location.lat === 'function' ? location.lat() : location.lat;
    const lng = typeof location.lng === 'function' ? location.lng() : location.lng;

    if (isNaN(lat) || isNaN(lng)) {
      throw new Error('Invalid origin coordinates');
    }

    originLatLng = new google.maps.LatLng(lat, lng);
  }

  // Handle different possible destination formats
  if (destination instanceof google.maps.LatLng) {
    destLatLng = destination;
  } else {
    const lat = typeof destination.lat === 'function' ? destination.lat() : destination.lat;
    const lng = typeof destination.lng === 'function' ? destination.lng() : destination.lng;

    if (isNaN(lat) || isNaN(lng)) {
      throw new Error('Invalid destination coordinates');
    }

    destLatLng = new google.maps.LatLng(lat, lng);
  }

  const service = new google.maps.DistanceMatrixService();
  const results = await getDistanceMatrix(service, {
    origins: [originLatLng],
    destinations: [destLatLng],
    travelMode: google.maps.TravelMode.DRIVING,
    unitSystem: google.maps.UnitSystem.METRIC,
  });
  
  return (results as any).rows[0].elements[0];
};
